#contact {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 32px;
}
#contact p {
  color: white;
  font-size: 1.5rem;
}
#contact .icons {
  display: flex;
  gap: 16px;
}
#contact .icon {
  border: 2px solid rgba(255, 255, 255, 0.25);
  padding: 24px;
  border-radius: 50%;
  transition: ease border 0.5s;
}
#contact .icon:hover {
  border-color: white;
}
#contact .icon svg {
  fill: white;
}
