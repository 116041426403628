.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.nav-pills {
  display: flex;
  gap: 32px;
}
.nav-link {
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-family: "Roboto Slab";
  font-weight: 700;
  font-size: 1.25rem;
  border: 2px solid transparent;
  padding: 8px 24px;
}
.nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.nav-pills .nav-link.active {
  border-radius: 24px;
  background-color: transparent;
  border: 2px solid white;
}
