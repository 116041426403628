.expertise-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
}
.expertise-grid__item {
  border: none;
  background-color: gray;
  border-radius: 24px;
  padding: 24px;
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.expertise-grid__item:hover {
  cursor: pointer;
}
.expertise-grid__item .x {
  display: inline-block;
}
.expertise-grid__item:hover .x {
  display: none;
}
.expertise-grid__item .years {
  display: inline-block;
  vertical-align: bottom;
  width: 0px;
  overflow: hidden;
  transition: width 2s;
}
.expertise-grid__item:hover .years {
  width: auto;
}
.expertise-grid__title {
  border: 2px solid white;
  color: white;
  padding: 12px 16px;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 900;
  font-family: "Roboto Slab", serif;
}
.expertise-grid__years {
  background-color: white;
  border-radius: 16px;
  display: inline-block;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 900;
  font-family: "Roboto Slab", "serif";
  position: absolute;
  bottom: 16px;
  right: 16px;
  margin: 0px;
}

@media screen and (min-width: 800px) {
  .expertise-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1000px) {
  .expertise-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
